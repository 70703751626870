import React, { useEffect, useMemo, useState } from "react"

import { Col, Row } from "antd"
import { map } from "lodash"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import Link from "next/link"
import { useRouter } from "next/router"

import MENU_ITEM from "../../enums/menu"

import { scrollToTop, scrollToElement } from "../../utils/helpers"

import {
	ImageSection,
	ActionSheet,
	MapTeaser,
	IconsSection,
	Button,
	Layout,
	Paragraph,
	ArticleHeading,
	MainHeading,
	SubHeading,
	CollectionView,
	Loading,
	LOADING_TYPE,
	Pagination,
} from "@bratislava/susedia-components"

import PageLayout from "../../components/Layout"
import { getThemeList, themeActions } from "../../redux/themes"
import { blogPostsActions, getBlogPostsList } from "../../redux/blog"
import { menuActions } from "../../redux/menu"

const IconGuides = require("../../assets/images/icons/icon-guides.svg")
const IconInfo = require("../../assets/images/icons/icon-info.svg")
const IconParticipate = require("../../assets/images/icons/icon-participate.svg")
const IconStories = require("../../assets/images/icons/icon-stories.svg")

const HomepageBackground = require("../../assets/images/backgrounds/homepage-background.png")
const ForeGardenImage = require("../../assets/images/backgrounds/foregarden-illustration.png")
const CommunityGardenImage = require("../../assets/images/backgrounds/community-illustration.png")
const TenThousandTrees = require("../../assets/images/backgrounds/10000-trees.png")
const MapBackground = require("../../assets/images/backgrounds/map-background.png")
const Mask1 = require("../../assets/images/backgrounds/mask-1.png")

const tipsTypes = ["info", "interests", "manuals", "getInvolved"]
const tipsIcons = [IconInfo, IconStories, IconGuides, IconParticipate]

const { Container, Section } = Layout

const HomePage = () => {
	const [t, i18n] = useTranslation()
	const dispatch = useDispatch()
	const router = useRouter()

	const [blogPostsPage, setBlogPostsPagePage] = useState(1)
	const [themesPage] = useState(1)

	useEffect(() => {
		scrollToTop()
	}, [])

	const articles = useSelector(getThemeList)
	const blogPosts = useSelector(getBlogPostsList)

	useEffect(() => {
		dispatch(menuActions.select(MENU_ITEM.ABOUT))
	}, [dispatch])

	useEffect(() => {
		dispatch(
			blogPostsActions.loadBlogList({ limit: 6, page: blogPostsPage })
		)
	}, [blogPostsPage])

	useEffect(() => {
		dispatch(themeActions.loadThemeList({ page: themesPage }))
	}, [themesPage])

	const tips = useMemo(
		() =>
			map(tipsTypes, (type, index) => ({
				title: t(`pages|Home.tips.${type}.title`),
				text: t(`pages|Home.tips.${type}.subtitle`),
				icon: {
					id: index,
					originalFile: type,
					smallSize: tipsIcons[index],
				},
			})),
		[i18n.language]
	)

	return (
		<PageLayout>
			<Section marginTop={{ xs: 30, sm: 30, md: 60 }}>
				<Container className={"wide"}>
					<Row align={"middle"}>
						<Col span={24} lg={12}>
							<MainHeading
								dangerouslySetInnerHTML={{
									__html: t("pages|Home.main-title", {
										interpolation: { escapeValue: false },
									}),
								}}
							/>
							<Section
								marginTop={{ xs: 0, sm: 0, md: 30 }}
								style={{ alignItems: "center" }}
							>
								<Row gutter={32}>
									<Col>
										<Link href={"/ako-sa-zapojit"}>
											<a href={"/ako-sa-zapojit"}>
												<Button>
													{t(
														"Zapojiť sa do projektu"
													)}
												</Button>
											</a>
										</Link>
									</Col>
									<Col>
										<Button
											onClick={() =>
												scrollToElement(
													`#${t("o-projekte")}`,
													100
												)
											}
											buttonType={"transparent"}
										>
											{t("Dozvedieť sa viac")}
										</Button>
									</Col>
								</Row>
							</Section>
						</Col>
						<Col span={24} lg={12}>
							<img
								src={HomepageBackground}
								alt={t("pages|Home.main-title")}
								style={{ width: "100%" }}
							/>
						</Col>
					</Row>
				</Container>
			</Section>

			<Section marginTop={{ xs: 0, sm: 0, md: 10 }}>
				<Container className={"narrow"}>
					<SubHeading id={t("o-projekte")}>
						{t("pages|Home.about")}
					</SubHeading>
					<Row>
						<Col span={24}>
							<ArticleHeading>
								{t("pages|Home.article-title")}
							</ArticleHeading>
							<Paragraph>
								{t("pages|Home.article-paragraph")}
							</Paragraph>
						</Col>
					</Row>
				</Container>

				<Container>
					<IconsSection content={tips} gutter={32} />
				</Container>

				<Container>
					<Row gutter={30}>
						<Col span={24} lg={12}>
							<ActionSheet
								title={t("foreGarden.title")}
								subtitle={t("foreGarden.subtitle")}
								steps={[
									t("foreGarden.step_1"),
									t("foreGarden.step_2"),
									t("foreGarden.step_3"),
								]}
								image={{ id: 0, originalFile: ForeGardenImage }}
								action={{
									title: t("Chcem zaregistrovať"),
									callback: () =>
										router.push("/temy/predzahradky"),
								}}
							/>
						</Col>
						<Col span={24} lg={12}>
							<ActionSheet
								title={t("communityGarden.title")}
								subtitle={t("communityGarden.subtitle")}
								steps={[
									t("communityGarden.step_1"),
									t("communityGarden.step_2"),
									t("communityGarden.step_3"),
								]}
								image={{
									id: 0,
									originalFile: CommunityGardenImage,
								}}
								marginTop={80}
								action={{
									title: t("Chcem zaregistrovať"),
									callback: () =>
										router.push("/temy/komunitne-zahrady"),
								}}
							/>
						</Col>
					</Row>
				</Container>
				{/* <Container className={'narrow'}>
					<SubHeading>{t('pages|Home.map')}</SubHeading>
					<ArticleHeading>{t('pages|Home.mapTitle')}</ArticleHeading>
					<Paragraph>{t('pages|Home.mapDescription')}</Paragraph>
				</Container>
				<Container className={'wide'}>
					<MapTeaser buttonTitle={t('Otvoriť zelenú mapu')} href={t(`paths|${MENU_ITEM.MAP}.path`)} mapBackground={MapBackground} />
				</Container> */}
				<Container className={"narrow"}>
					<SubHeading>{t("pages|Home.inform")}</SubHeading>
					<ArticleHeading>
						{t("pages|Home.informTitle")}
					</ArticleHeading>
				</Container>
				<br />
				<Container>
					{!!articles.data?.themes && (
						<CollectionView
							urlPath={t("paths|TOPICS.path")}
							linkComponent={Link}
							type={"parent"}
							items={
								articles.data?.themes.filter(
									(theme: any) => theme.displayInMenu
								) || []
							}
						/>
					)}
				</Container>

				<Container>
					<ImageSection
						subtitle={t("pages|Home.10000.subtitle")}
						title={t("pages|Home.10000.title")}
						text={t("pages|Home.10000.paragraph")}
						buttonText={t("pages|Home.10000.button")}
						buttonHref={"https://10000stromov.sk/"}
						image={{
							id: 0,
							originalFile: TenThousandTrees,
							smallSize: TenThousandTrees,
						}}
					/>
				</Container>

				<Container className={"narrow"}>
					<SubHeading>{t("pages|Home.read")}</SubHeading>
					<ArticleHeading>{t("pages|Home.readTitle")}</ArticleHeading>
				</Container>

				<Container>
					{blogPosts.isLoading && (
						<Loading type={LOADING_TYPE.ARTICLES} />
					)}

					{!!blogPosts.data?.blogPosts && (
						<CollectionView
							urlPath={t("paths|BLOG.path")}
							linkComponent={Link}
							type={"subArticle"}
							maskImage={Mask1}
							items={blogPosts.data?.blogPosts || []}
						/>
					)}

					{!!blogPosts.data?.context && (
						<Pagination
							context={blogPosts.data.context}
							onChange={setBlogPostsPagePage}
						/>
					)}
				</Container>

				<Container>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							marginTop: "46px",
						}}
					>
						<a href={t("paths|BLOG.path")}>
							<Button>{"Zobraziť viac článkov"}</Button>
						</a>
					</div>
				</Container>
			</Section>

			<Section>
				<Container className={"narrow"}>
					<SubHeading>
						{t("pages|Home.neighboursSubtitle")}
					</SubHeading>
					<Row>
						<Col span={24}>
							<ArticleHeading>
								{t("pages|Home.neighboursTitle")}
							</ArticleHeading>
							<ArticleHeading className={"primary"}>
								{t("pages|Home.neighboursHighlight")}
							</ArticleHeading>
						</Col>
					</Row>
				</Container>
			</Section>
		</PageLayout>
	)
}

export default HomePage
