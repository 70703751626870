import React from "react"
import HomePage from "../containers/Home/HomePage"

import { GetServerSideProps } from "next"

import { setupStore } from "../redux"

import { articles } from "../api"
import SEOHelmet from "../components/SEOHelmet"
import { themeActions } from "../redux/themes"

type Props = {
	pathname: string
	shareTags: {}
}

const HomePageComponent = ({ shareTags }: Props) => {
	return (
		<>
			<SEOHelmet pageMetadata={shareTags} />
			<HomePage />
		</>
	)
}

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
	const { data } = await articles.loadThemes({})

	const store = setupStore()

	store.dispatch(themeActions.loadThemesSelect(data))

	const shareTags = {
		url: `https://${req.headers.host}${req.url}`,
		title: "Bratislavskí susedia | Mesto Bratislava",
		description: "Bratislavskí susedia | Bratislava - Digitálne konto",
		type: "article",
	}

	return {
		props: {
			initialReduxState: store.getState(),
			shareHref: req.url,
			shareTags,
			pathname: req.url,
		},
	}
}

export default HomePageComponent
